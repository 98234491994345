(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("classnames"), require("formik"), require("lodash/omit"), require("lodash/throttle"), require("prop-types"), require("react"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("UIKit", ["classnames", "formik", "lodash/omit", "lodash/throttle", "PropTypes", "React", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["UIKit"] = factory(require("classnames"), require("formik"), require("lodash/omit"), require("lodash/throttle"), require("prop-types"), require("react"), require("react-router-dom"));
	else
		root["UIKit"] = factory(root["classnames"], root["formik"], root["lodash/omit"], root["lodash/throttle"], root["PropTypes"], root["React"], root["react-router-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__79594__, __WEBPACK_EXTERNAL_MODULE__10258__, __WEBPACK_EXTERNAL_MODULE__29108__, __WEBPACK_EXTERNAL_MODULE__66198__, __WEBPACK_EXTERNAL_MODULE__48740__, __WEBPACK_EXTERNAL_MODULE__70156__, __WEBPACK_EXTERNAL_MODULE__10978__) {
return 